.App {
  background-color: #fffbe8;
  background-image: url('Assets/KRUS\ fade\ transparent\ Mobile\ scale.png');
  background-size: 100%;
  background-repeat: no-repeat;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
}

body{
  margin: 0;
}

.topPage{
  height: 100svh;
  margin: 0;
  padding: 0;
  flex: auto;
  top: 0;
}

@font-face {
  font-family: 'RobotoMonoBoldItalic';
  src: url('/Users/lukasbroberg/Documents/KRUS/Website/krusweb/Assets/static/RobotoMono-MediumItalic.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

header {
  display: none;
}

.centerBody{
margin-left: auto;
margin-right: auto;
width: 80%;
height: 100%;
}

.signUpBody{
  z-index: 5;
  position: relative;
  display: block;
  top: 50%;
}

h1{
  font-size: 52;
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 300;
  line-height: 100px;
  letter-spacing: 2px;
  color: lightgray;
  text-align: center;
  z-index: 8;
}

h2{
  font-size: 32px;
  font-family: 'RobotoMonoBoldItalic';
  font-style: regular;
  font-weight: 100;
  letter-spacing: 2px;
  margin: 0px;
  margin-bottom: 10px;
  color: white;
  margin-left: 0px;
  column-span: 1;
}

.staticBodyText{
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  letter-spacing: 2px;
  font-size: 20px;
  line-height: 'auto';
  margin-top: 50px;
  margin-bottom:70px;

  letter-spacing: 2px;
  color: white;
}

.scrollDownFill{
  width: 100%;
  position: absolute;
  text-align: center;
  display: flex;
  bottom: 0px;
  padding-bottom:50px;
  background-color: transparent;
}

.scrollButton{
  transition: 0.2s ease-in;
  position: absolute;
  width: 50%;
  right: 25%;
  padding: 0px;
}

.scrollButton:hover{
  transition: 0.2s ease-in;
  margin-top: 10px;
  color: gray;
}

.aboutColumn{
  margin: 0px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column-reverse;
}

.aboutColumn img{
  margin: auto;
  display: block;
}

.emailInput{
  font-size: 24px;
  display: inline-block;
  margin: 5px;
  height: 30px;
  min-width: 150px;
  width: 100%;
  background-color: transparent;
  font-family: 'RobotoMonoBoldItalic';
  color: white;
  letter-spacing: 3px;
  border: none;
  overflow: hidden;

}
.emailInput:focus{
  outline: none;
  border: none;
}

.emailInput::placeholder{
  color: white;
}



.topBrandMugImage{
  display: none;
}

#topBrandImageMobile{
  display: block;
  position: absolute;
  left: 0;
  margin: 0;
  width: 100%;
  bottom: 0;
}

label{
  font-size: 12px;
  font-family: 'Roboto';
  margin: 0px;
  color: gray;
}

button{
  font-size: 36px;
  background-color: transparent;
  font-family: 'Roboto';
  display:block;
  padding: 0px 5px 2px 0px;
  color: white;
  transition: .2s;
  transition-timing-function: ease-in;
  border: none;
  float: right;
}
    button:hover{
      transition: .2s;
      transition-timing-function: ease-out;
      color: black;
    }

    button:active{
      transition: .05s;
    }

.userInputField{
  background-color: #3a3a3a;
  padding-left: 2px;
  padding-right: 2px;
  padding: 5px;
  justify-items: center;
  margin: 0;
  transition: .2s;
  transition-timing-function: ease-in;
  border-radius: 35px;
  display: flex;
  flex-direction: row;
  border: solid 1px #3a3a3a;
}
    .userInputField:hover{
      background-color: #3a3a3a3d;
      transition: .2s;
      transition-timing-function: ease-out;
      border-radius: 35px;
      border: solid 1px #3a3a3a;
    }

.labelForEmailInput{
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 600;
  font-style: italic;
  color: #3a3a3a;
  margin-bottom:4px;
  margin-top: 10px;
  padding: 0px;
}

.bottomPageSignupInput{
  display: none;
}


.bottomPage{
  background-color: #282828;
  height: fit-content;
}

.h1BottomPage{
padding: 15px;
margin: 0px;
  font-size: 32px;
}

#h1TopPage{
  display: none;
}



.bottomPageBodyArticle{
  margin-left: 0px;
  margin-right: 0px;
  padding: 50px;
  background-color: #3a3a3a;
  height: fit-content;
}

footer{
  text-align: center;
  width: 100%;
  color: #a0a0a0;
}
.footerIcon{
  padding: 10px;
  margin-bottom: 50px;
  margin-top: 10px;
  position: relative;
  transition: 0.5s;
  color: #474747;
}
.footerIcon:hover{
  transition: 0.5s;
  color: white;
  cursor:pointer;
}

.ThankYou{
  font-size: 32px;
  color: rgb(139, 139, 139);
  margin-left: 4.32vw;
  margin-right: 4.32vw;
  font-family: 'Roboto';
  z-index: 10;
  position: relative;
}

a{
  color: inherit;
}
@media only screen and (max-height: 480px) and (orientation: landscape) {
  .scrollDownFill{
    position: relative;
  }
}

@media only screen and (min-width: 700px) and (orientation: landscape){ 
  
  .topPage{
    background-color: #fffbe8;
    min-height: 550px;
  }
  
  .topBrandMugImage{
    display:block;
    position: absolute;
    width: 65vw;
    z-index: 0;
    top: 0;
    right: 0;
  }

  #topBrandImageMobile{
    display: none;
  }

  header{
    text-align: center;
    justify-content: center;
    font-size: 25vw;
    font-family: 'Roboto';
    padding-top: 50px;
    font-weight: 900;
    line-height: 20vw;
    margin-left: 4vw;
    letter-spacing: 5vw;
    color: #f9e4bc;
    margin-bottom: -60px;
    z-index: 2;
    position: relative;
    display: block;
  }

  h1{
    font-size: 4vw;
  }
  
  .centerBody{
    width: fit-content;
    margin: auto;
    margin-left: 10vw;
    height: fit-content;
  }

  .userInputField{
    background-color: #3a3a3a;
    border: solid 1px #3a3a3a;
    width: fit-content;
    padding-left: 5px;
    padding-right: 20px;
    transition: .2s;
    transition-timing-function: ease-in;
    border-radius: 35px;
  }

  .emailInput{
    font-size: 26px;
    padding: 12px;
    width: 35vw;
  }

  button{
    font-size: 36px;
    cursor:pointer;
    position: relative;
    display: inline-block;

    float: none;
  }

  .labelForEmailInput{
    font-size: 24px;
    font-family: 'Roboto';
    font-weight: 600;
    font-style: italic;
  }

  .signUpBody{
    width: fit-content;
    top: 0;
  }

  .staticBodyText{
    font-size: 1.5vw;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 200px;
    max-width: 50vw;
  }

  .bottomPageBodyArticle{
    margin-left: 15vw;
    margin-right: 15vw;
  }

  .bottomPageSignupInput{
    width: 50%;
    margin: auto;
    padding: 20px;
    margin-top: 50px;
    margin-top: 50px;
    display: flex;
  }

  .h1BottomPage{
    padding-top: 100px;
    padding-bottom: 100px;
    font-size: 42px;
  }

  h2{
    font-size: 3vw;
  }

  .aboutColumn{
      margin-bottom: 2vh;
      display: flex;
      flex-direction: row;
  }

  #FLADMugSunset{
    margin-top: -120px;
    height: 40vw;
    width: 40vw;
  }

  #FLADMugCloseUpSolidGrey{
    height: 30vw;
    width: 30vw;
  }
  
  #aboutColumnReversed{
    flex-direction: row-reverse;
  }

  img{
    display: block;
  }

  #h1TopPage{
    display:flex;
  }

  .footerIcon{
    margin-bottom: 100px;
    margin-top: 10px;
  }
}
